import React from 'react'

const HomePage: React.FC = () => {
  return (
    <div>
      <h1>Welcome to the User Home Page</h1>
    </div>
  )
}

export default HomePage
