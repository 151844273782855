import React, { useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../../app/stores/store.ts";
import OrderItemList from "./OrderItemList.tsx";

const OrderItemDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { orderStore } = useStore();
  const { loadOrderById, setPagingParams, loadingFilters, selectedOrder } =
    orderStore;

  const handleBackClick = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: orderStore.pagingParams.pageSize,
    });
    navigate("/admin/orders");
  };

  const handleAddItemsToOrder = () => {
    const serializedOrder = JSON.parse(JSON.stringify(selectedOrder));
    navigate(
      `/admin/addorderitem?orderId=${selectedOrder?.id}&clientId=${selectedOrder?.client.id}`,
      { state: { order: serializedOrder } }
    );
  };

  useEffect(() => {
    loadOrderById(id ?? "");
  }, [loadOrderById, id]);

  if (loadingFilters) return <CircularProgress />;

  const orderId = id ?? selectedOrder?.id ?? "";
  const orderApproved = selectedOrder?.isApproved ?? false;

  return (
    <div>
      <h1>Order Items</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h2>Order Number: {selectedOrder?.orderNumber}</h2>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddItemsToOrder}
            disabled={orderApproved} // Disable button if the order is approved
          >
            Add Items to Order
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBackClick}
            style={{ marginLeft: "10px" }}
          >
            Back to Orders List
          </Button>
        </Box>
      </Box>
      <OrderItemList orderId={orderId} orderApproved={orderApproved} />
    </div>
  );
};

export default observer(OrderItemDashboard);
