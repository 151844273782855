import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react-lite";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../app/stores/store.ts";
import SelectClientForm from "./SelectClientForm.tsx";
import PlaceOrderList from "./PlaceOrderList.tsx";
import PlaceOrderFilters from "./PlaceOrderFilters.tsx";
import { toJS } from "mobx";
import { Order } from "../../../app/models/order.ts";
//page created by @riniyad
//requested-tony
const AddItemsToOrder: React.FC = () => {
  const navigate = useNavigate();
  const {
    commonStore,
    clientStore,
    productStore,
    cartStore,
    orderStore,
    userStore,
  } = useStore();
  const { isOrderMode, selectedClient } = commonStore;
  const { setPlaceOrderMode, clearplaceOrderProducts } = productStore;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("orderId");
  const clientId = searchParams.get("clientId");

  const userRole = userStore.user?.role;

  const locationstate = location.state || {};
  const order = locationstate?.order as Order;
  const { loadingInitial, clients, loadActiveClients, getClientById } =
    clientStore;
  const { cartItems, totalItems, totalPrice } = cartStore;
  const { saveOrder, createOrderModel } = orderStore;

  const handleCancelOrder = () => {
    if (commonStore) {
      cartStore.clearCart();
      productStore.loadPlaceOrderProducts();
    } else {
      console.error("commonStore is undefined");
    }
  };

  const handleAddItemstoOrder = async () => {
    if (order) {
      const newOrder = createOrderModel(cartItems, order.client);
      await orderStore.addItemsToOrder(order.id!, newOrder.orderItems);
      //navigate(-1);
      const orderId = order.id;

      if (userRole === "Admin") {
        navigate(`/admin/orders/${orderId}`);
      } else {
        navigate(`/user/myorders/${orderId}`);
      }
    }
  };

  useEffect(() => {
    setPlaceOrderMode(true);
  }, [setPlaceOrderMode]);

  useEffect(() => {
    if (order) {
      if (commonStore) {
        commonStore.setSelectedClient(order.client);
        commonStore.setOrderMode(true);
      }
    }

    // Cleanup function to reset cart and selected client
    return () => {
      if (commonStore) {
        commonStore.setSelectedClient(null); // or any default value
        cartStore.clearCart();
        commonStore.setOrderMode(false);
      }
    };
  }, [order, orderId, clientId]);

  return (
    <div>
      {isOrderMode ? (
        <>
          <h1>Add Items To Order</h1>
          <h2>Client: {selectedClient?.name}</h2>
          <h2>Order: {order?.orderNumber}</h2>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center" // Align items vertically centered within the container
          >
            <PlaceOrderFilters />
            <Box display="flex" gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddItemstoOrder}
              >
                Add to Order
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<CloseIcon />}
                onClick={handleCancelOrder}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <PlaceOrderList />
          <Outlet />
        </>
      ) : (
        <SelectClientForm />
      )}
    </div>
  );
};

export default observer(AddItemsToOrder);
