import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { Order } from "../../../../app/models/order.ts";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { OrderItem } from "../../../../app/models/orderItem.ts";
//Added by Riniyad - requester Tony - to handle export to csv
const exportToExcel = (order: Order) => {
  const { orderNumber, orderDate, client, userName, itemsCount, orderItems } =
    order;

  const data: any[][] = [];

  // Add order-level information in two columns (Label and Value)
  data.push(["Order Number", orderNumber]);
  data.push(["Order Date", formatDate(orderDate)]);
  data.push(["Client", client.name]);
  data.push(["Sales Agent", userName]);
  data.push(["Total Items", itemsCount]);
  data.push(["Order Total", order.subTotal]);

  // a blank row to separate order info from order items
  data.push([]);

  // header row for order items
  data.push([
    "Product Code",
    "Product Name",
    "Price",
    "Quantity",
    "Case/Unit",
    "Total Price",
  ]);

  // Add each order
  orderItems.forEach((item: OrderItem) => {
    data.push([
      item.product.code,
      item.product.name,
      item.unitPrice,
      item.quantity,
      item.byCase ? "case" : "unit",
      item.totalPrice,
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Order Details");
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([buffer], { type: "application/octet-stream" });

  saveAs(blob, `${orderNumber}.xlsx`);
};

const OrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrders,
    approveOrder,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    isApprovedFilter,
  } = orderStore;
  const navigate = useNavigate();

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrders();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const handleToggle = (id: string) => {
    approveOrder(id).then(() => navigateToHome(1));
  };
  //Added by Riniyad - requester Tony - to handle Revoke,Delete,and additems to order
  const handleRevoke = (id: string) => {
    orderStore.revokeOrder(id).then(() => navigateToHome(1));
  };

  const handleDeleteOrder = (orderId: string) => {
    orderStore.deleteOrder(orderId).then(() => {
      loadOrders();
    });
  };

  const handleAddItemstoOrder = (order: Order) => {
    const serializedOrder = JSON.parse(JSON.stringify(order));
    navigate(
      `/admin/addorderitem?orderId=${order.id}&clientId=${order.client.id} `,
      { state: { order: serializedOrder } }
    );
  };

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  if (loadingInitial) return <CircularProgress />;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#525252" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Number
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Client
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  User
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Sub Total
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Items
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Approved
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Items
                </Typography>
              </TableCell>
              {!isApprovedFilter ? (
                <>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Approve
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Add Items to Order
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Delete Order
                    </Typography>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Revoke
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Export to Excel
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell>{order.orderNumber}</TableCell>
                  <TableCell>{formatDate(order.orderDate)}</TableCell>
                  <TableCell>{order.client.name}</TableCell>
                  <TableCell>{order.userName}</TableCell>
                  <TableCell>${order.subTotal}</TableCell>
                  <TableCell>{order.itemsCount}</TableCell>
                  <TableCell>{order.isApproved.toString()}</TableCell>
                  <TableCell>
                    <IconButton component={Link} to={`${order.id}`}>
                      <OpenInNewIcon />
                    </IconButton>
                  </TableCell>

                  {order.isApproved ? (
                    <>
                      <TableCell>
                        <Button onClick={() => handleRevoke(order.id ?? "")}>
                          Revoke
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => exportToExcel(order)}
                        >
                          Export
                        </Button>
                      </TableCell>
                      <TableCell></TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <Button onClick={() => handleToggle(order.id ?? "")}>
                          Approve
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddItemstoOrder(order)}
                        >
                          Add Items
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteOrder(order.id!)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default observer(OrderList);
