import { makeAutoObservable, runInAction } from "mobx";
import { Order } from "../models/order";
import { OrderItem } from "../models/orderItem";
import agent from "../api/agent.ts";
import { Pagination, PagingParams } from "../models/pagination.ts";
import { Client } from "../models/client.ts";
import { AppUser } from "../models/user.ts";
import { CartItem } from "../models/cartItem.ts";
import { v4 as uuid } from "uuid";

export default class OrderStore {
  orders: Order[] = [];
  orderItems: OrderItem[] = [];
  clients: Client[] = [];
  users: AppUser[] = [];
  selectedOrder: Order | undefined = undefined;
  loadingInitial = true;
  loadingFilters = true;
  submitting = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  isApprovedFilter = true;
  clientFilter = "";
  userFilter = "";
  orderNumberFilter = "";
  constructor() {
    makeAutoObservable(this);
  }

  loadOrders = async () => {
    try {
      let params = new URLSearchParams();

      if (this.clientFilter) params.append("clientId", this.clientFilter);
      if (this.userFilter) params.append("userName", this.userFilter);
      if (this.orderNumberFilter)
        params.append("orderNumber", this.orderNumberFilter);

      params.append("isApproved", String(this.isApprovedFilter));
      params.append("pageNumber", this.pagingParams.pageNumber.toString());
      params.append("pageSize", this.pagingParams.pageSize.toString());

      const response = await agent.Orders.list(params);

      runInAction(() => {
        this.setOrders(response.data);
        this.setPagination(response.pagination);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  loadMyOrders = async () => {
    try {
      let params = new URLSearchParams();

      if (this.clientFilter) params.append("clientId", this.clientFilter);
      if (this.orderNumberFilter)
        params.append("orderNumber", this.orderNumberFilter);

      params.append("isApproved", String(this.isApprovedFilter));
      params.append("pageNumber", this.pagingParams.pageNumber.toString());
      params.append("pageSize", this.pagingParams.pageSize.toString());

      const response = await agent.Orders.myOrdersList(params);

      runInAction(() => {
        this.setOrders(response.data);
        this.setPagination(response.pagination);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  loadOrderItems = async (orderId: string) => {
    this.loadingInitial = true;
    try {
      let params = new URLSearchParams();
      params.append("orderId", orderId);

      params.append("pageNumber", this.pagingParams.pageNumber.toString());
      params.append("pageSize", this.pagingParams.pageSize.toString());

      const response = await agent.Orders.itemsList(params);
      runInAction(() => {
        this.setOrderItems(response.data);
        this.setPagination(response.pagination);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  loadActiveClients = async () => {
    try {
      const params = new URLSearchParams();
      params.append("isActive", "true");

      const response = await agent.Clients.list(params);

      runInAction(() => {
        this.setClients(response.data);
        this.setLoadingFilters(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingFilters(false);
      });
    }
  };

  loadActiveUsers = async () => {
    try {
      const params = new URLSearchParams();
      params.append("isActive", "true");

      const response = await agent.AppUsers.list(params);

      runInAction(() => {
        this.setUsers(response.data);
        this.setLoadingFilters(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingFilters(false);
      });
    }
  };

  loadOrderById = async (id: string) => {
    let order = this.getOrderById(id);
    if (order) {
      this.setSelectedOrder(order);
      return order;
    } else {
      this.setLoadingFilters(true);
      try {
        order = await agent.Orders.details(id);
        runInAction(() => {
          this.selectedOrder = order;
          this.setLoadingFilters(false);
        });
        return order;
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.setLoadingFilters(false);
        });
      }
    }
  };

  getOrderById = (id: string) => {
    return this.orders.find((order) => order.id === id);
  };

  createOrderModel = (cartItems: CartItem[], client: Client) => {
    let order: Order = {
      id: uuid(),
      orderNumber: "123",
      orderDate: new Date(),
      clientId: client.id,
      client: client,
      userName: "",
      userId: "",
      subTotal: 0,
      itemsCount: 0,
      orderItems: [],
      isApproved: false,
    };

    order.orderItems = cartItems.map((item) => {
      let orderItem: OrderItem = {
        id: uuid(),
        product: item.product,
        productId: item.id,
        orderId: order.id ?? "",
        quantity: item.quantity,
        unitPrice: item.price,
        totalPrice: item.price * item.quantity,
        byCase: item.byCase,
      };
      order.subTotal += orderItem.totalPrice;
      order.itemsCount += orderItem.quantity;
      return orderItem;
    });
    return order;
  };

  saveOrder = async (order: Order) => {
    this.submitting = true;
    try {
      await agent.Orders.create(order);
      this.setSubmitting(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  approveOrder = async (id: string) => {
    this.setSubmitting(true);
    try {
      await agent.Orders.toggleActive(id);
      runInAction(() => {
        let order = this.getOrderById(id);
        if (order) {
          order.isApproved = true;
        }
        this.setSubmitting(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setSubmitting(false);
      });
    }
  };
  //changes done by riniyad : Change request : Tony
  //Funtionality Added : Admin Can Toggle between Approval and Rejection of Orders, Delete Orders
  //revokeOrder Method: Implement the revokeOrder method in the OrderStore to handle the revocation request.
  //DeleteOrder Method: Implement the delete method in the OrderStore to handle the deletion request.
  //updateOrderItem method: handles edit request to an order
  //deleteOrderItem method: handles deletetion of orderItem
  revokeOrder = async (id: string) => {
    try {
      await agent.Orders.revoke(id);
      runInAction(() => {
        const order = this.orders.find((o) => o.id === id);
        if (order) order.isApproved = false;
      });
    } catch (error) {
      console.error("Failed to revoke the order:", error);
    }
  };
  deleteOrder = async (id: string) => {
    try {
      await agent.Orders.delete(id);
      runInAction(() => {
        this.orders = this.orders.filter((order) => order.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };
  updateOrderItem = async (orderItem: OrderItem) => {
    this.submitting = true;
    try {
      // Check if the order is approved
      const order = this.orders.find((o) => o.id === orderItem.orderId);
      if (order && order.isApproved) {
        throw new Error("Cannot edit an approved order.");
      }

      // Proceed with updating the order item
      await agent.Orders.updateOrderItem(orderItem);

      runInAction(() => {
        const order = this.orders.find((o) => o.id === orderItem.orderId);
        if (order) {
          const itemIndex = order.orderItems.findIndex(
            (item) => item.id === orderItem.id
          );
          if (itemIndex !== -1) {
            order.orderItems[itemIndex] = orderItem;
            order.subTotal = order.orderItems.reduce(
              (total, item) => total + item.totalPrice,
              0
            );
            order.itemsCount = order.orderItems.reduce(
              (count, item) => count + item.quantity,
              0
            );
          }
        }
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.submitting = false;
      });
    }
  };
  deleteOrderItem = async (orderId: string, itemId: string) => {
    try {
      await agent.Orders.deleteOrderItem(orderId, itemId);
      runInAction(() => {
        if (this.selectedOrder) {
          this.selectedOrder.orderItems = this.selectedOrder.orderItems.filter(
            (item) => item.id !== itemId
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  highlightedItems: Set<{ id: string; byCase: boolean }> = new Set();

  // Update to handle multiple highlighted item IDs with `id` and `byCase`
  setHighlightedItems(items: { id: string; byCase: boolean }[]) {
    this.highlightedItems = new Set(
      items.map((item) => ({ id: item.id, byCase: item.byCase }))
    );

    setTimeout(() => {
      runInAction(() => {
        this.highlightedItems.clear();
      });
    }, 10000); // Clear highlights after 10 seconds
  }
  addItemsToOrder = async (orderId: string, items: OrderItem[]) => {
    try {
      const response = await agent.Orders.addOrderItems(orderId, items);
      runInAction(() => {
        this.loadOrders();
        // Highlight items by `id` and `byCase` property
        this.setHighlightedItems(
          items.map((item) => ({ id: item.productId, byCase: item.byCase }))
        );
      });
    } catch (error) {
      console.log("Failed to add items to order", error);
    }
  };

  ////----------------@riniyad------

  setSubmitting = (submitting: boolean) => {
    this.submitting = submitting;
  };

  setLoadingInitial = (loadingInitial: boolean) => {
    this.loadingInitial = loadingInitial;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setClientFilter = (value: string) => {
    this.clientFilter = value;
  };

  setUserFilter = (value: string) => {
    this.userFilter = value;
  };

  setOrderNumberFilter = (value: string) => {
    this.orderNumberFilter = value;
  };

  setIsApprovedFilter = (value: boolean) => {
    this.isApprovedFilter = value;
  };

  setOrders = (orders: Order[]) => {
    this.orders = orders;
  };

  setOrderItems = (orderItems: OrderItem[]) => {
    this.orderItems = orderItems;
  };

  setClients = (clients: Client[]) => {
    this.clients = clients;
  };

  setUsers = (users: AppUser[]) => {
    this.users = users;
  };

  setLoadingFilters = (loadingFilters: boolean) => {
    this.loadingFilters = loadingFilters;
  };

  setSelectedOrder = (order: Order) => {
    this.selectedOrder = order;
  };

  clearSelectedOrder = () => {
    this.selectedOrder = undefined;
  };
}
