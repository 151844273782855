export const ADMIN_MENU_ITEMS = [
  "Home",
  "Users",
  "Clients",
  "Manufacturers",
  "Categories",
  "Products",
  "Orders",
];

export const USER_MENU_ITEMS = ["Home", "PlaceOrder", "MyOrders"];
